@import "~@sportaus-digital/core/src/scss/colours";
@import "./_fonts.scss";

//
// Sport Connect global variables
//

// SDS variables
$SDS-page-max-width: 1540px; // Max width of web page
$SDS-page-content-max-width: 1140px; // Max width of content within web page - static content
$SDS-page-content-admin-max-width: 1000px; // Max width of content within web page - admin content

$SDS-color-background: $asc-blue-0;
$SDS-color-foreground-action: $asc-blue-0;
$SDS-color-foreground-focus: $asc-blue-0;
$SDS-color-foreground-text: $white;
$SDS-font: Simplon, Helvetica Neue, Arial, sans-serif;

$SDS-border-radius: 5px;

//
// @sportaus-connect design system component overrides
//
$sac-accordion-button-color: $sds-navy !important;
$sac-accordion-button-focus-outline: 3px solid $sds-green-secondary !important;

//
// Font Awesome integration
//
@import "~@fortawesome/fontawesome-free/css/all.css";

//
// Bootstrap integration
//
@import "./_bootstrap-customisations.scss";

//
// DTA design system integration
//
@import "./_dta-customisations.scss";

//
// Sport Connect global styles
//
body {
  font-family: $SDS-font;
  background: $sds-white;
  margin: 0;
  height: 100%;

  .skippy {
    display: block;
    padding: 1rem;
    color: #ffffff;
    text-align: center;
    background-color: #E0E0E0;
    transition: all 0.3s ease-in;
  }

  .skippy:hover, .skippy:focus, .skippyactive {
    background-color: #333333;
    color: #9ECF7C;
  }

  .header {
    width: 100%;
    min-height: 5rem;
    border-bottom: 1px solid $sds-grey-medium;

    .header-logo {
      float: left;
      max-width: 12rem;
      margin: 1rem 1rem 1rem 2rem;
    }

    .user-menu {
      display: inline-block;
      float: right;
      margin-top: 1rem;
      margin-right: 1rem;
    }
  }

  .sds-content {
    height: 100%;

    a {
      color: $sds-navy;

      &:hover, &:focus, &:active {
        color: $sds-navy;
      }
    }
  }

  .container-fixed-width {
    background: $sds-white;
    max-width: $SDS-page-max-width;
    margin: 0 auto;
    height: 100%;
  }

  // Containers
  // Self service
  .sds-container__self-service {
    margin-left: -12px !important;
    margin-right: -12px !important;
    position: relative !important;
  }

  .container-right {
    width: auto !important;
    padding-left: 12px;
    padding-right: 12px;

    .container-content {
      max-width: $SDS-page-content-admin-max-width;
      padding-top: 2rem;
    }
  }

  .container-content {
    max-width: $SDS-page-content-max-width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  // Typography
  // Sizing

  h1, h2, h3, h4, h5, h6 {
    color: $sds-navy-dark;
    font-weight: 500;
  }

  .sport-links {
    margin-top: 4rem;
    display: flex;
    flex-wrap: wrap;

    .sport-link {
      flex: 1;
      padding-left: 10px;
      padding-right: 10px;
      min-width: 270px;
      min-height: 75px;
      max-width: 270px;
      max-height: 75px;
      line-height: 75px;
      margin: 1rem;
      text-align: center;
      border: 1px solid $sds-grey-light;
      background: $sds-grey-lightest;
      border-radius: 5px;
      font-size: 1.5rem;
      font-weight: 500;
      overflow: hidden;
    }
  }

  .scopes {
    max-width: 500px;
    width: 100%;
  }
}